export interface TableListItem  {
  key: number;
  name: string;
};

export enum AsyncStatus {
  Pending = 'pending',
  Idle = 'idle',
  Success = 'success',
  Error = 'error'
}
