// src/pages/Dashboard.tsx
import React from 'react';
import { Card } from 'antd';

const DashboardPage: React.FC = () => {
  return (
    <Card title="Dashboard" style={{ margin: 24 }}>
      Welcome to the Dashboard!
    </Card>
  );
};

export default DashboardPage;
