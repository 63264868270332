// src/components/AuthProvider.tsx
import React, { createContext, useContext, useState, ReactNode, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Client } from '../services/Client';
import { User } from '../services/Api';

interface AuthContextType {
  token: string | null;
  login: (credentials: { email: string; password: string }) => Promise<void>;
  logout: () => void;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [token, setToken] = useState<string | null>(sessionStorage.getItem('token'));
  const navigate = useNavigate();

  useEffect(() => {
    // Устанавливаем интерсептор для обработки ошибок 401
    const interceptor = Client.http.instance.interceptors.response.use(
      response => response,
      error => {
        if (error.response && error.response.status === 401) {
          logout();  // Выходим из системы при статусе 401
        }
        return Promise.reject(error);
      }
    );

    // Очищаем интерсептор при размонтировании компонента
    return () => {
      Client.http.instance.interceptors.response.eject(interceptor);
    };
  }, []);

  const login = async (credentials: { email: string; password: string }) => {
    const response = await Client.auth.login(credentials)

    const data=response.data;

    if (data) {
      console.log(data)
      sessionStorage.setItem('token', data.token);
      setToken(data.token);
      navigate('/dashboard');
    } else {
      throw new Error('Invalid credentials');
    }
  };

  const logout = () => {
    sessionStorage.removeItem('token');
    setToken(null);
    navigate('/login');
  };

  return <AuthContext.Provider value={{ token, login, logout }}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) throw new Error("useAuth must be used within an AuthProvider");
  return context;
};
