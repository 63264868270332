import { ColumnsState, ProColumns } from '@ant-design/pro-components';
import { Role, User } from '../services/Api';
import { CheckCircleTwoTone, CloseCircleTwoTone, EditOutlined, PlusOutlined, UsergroupAddOutlined, UsergroupDeleteOutlined } from '@ant-design/icons';
import ButtonGroup from 'antd/es/button/button-group';
import { Button } from '../components/button/button';
import { Popconfirm } from 'antd';
import { MutableRefObject, useRef, useState } from 'react';
import { TableFullHeight } from '../components/table/table-full-height';
import { Client } from '../services/Client';
import { ProFormInstance } from '@ant-design/pro-form';
import { SideForm } from '../components/form/side-form';
import { UserBaseForm } from '../components/form/user-base.form';
import { useFormSubmit } from '../hooks/use-form-submit';

export const UsersPage = () => {
  const [userEdit, setUserEdit] = useState<User>();
  const tableRef: MutableRefObject<any> = useRef();
  const createUserFormRef: MutableRefObject<any> = useRef();
  const updateUserFormRef: MutableRefObject<any> = useRef();
  const profileFormRef: MutableRefObject<any> = useRef();
  const [userCreate, userCreateStatus] = useFormSubmit(Client.users.create, createUserFormRef);
  const [userUpdate, userUpdateStatus] = useFormSubmit(Client.users.update, updateUserFormRef);

  const [createUserFormVisible, setCreateUserFormVisible] = useState(false);

  const [columnsStateMap, setColumnsStateMap] = useState<Record<string, ColumnsState>>({});
  const columns: ProColumns[] = [
    {
      title: 'id',
      width: 80,
      dataIndex: 'id',
      copyable: true,
      sorter: true,
    },
    {
      title: 'Телефон',
      //width: 80,
      dataIndex: 'phone',
      copyable: true,
    },
    {
      title: 'Email',
      //width: 80,
      dataIndex: 'email',
      copyable: true,
    },
    {
      title: 'Имя',
      //width: 100,
      dataIndex: 'first_name',
      sorter: true,
    },
    {
      title: 'Фамилия',
      //width: 100,
      dataIndex: 'last_name',
      sorter: true,
    },
    {
      title: 'Роль',
      dataIndex: 'role',
      //width: 50,
      valueType: 'checkbox',
      valueEnum: Role,
    },

    {
      title: 'Статус',
      dataIndex: 'is_active',
      //width: 30,
      valueType: 'switch',
      render: (_, record: User) => {
        return record.is_active ? <CheckCircleTwoTone twoToneColor={'#48f306'}/> : <CloseCircleTwoTone twoToneColor={'red'}/>;
      },
    },
    {
      title: 'Дата',
      dataIndex: 'createdAt',
      //width: 50,
      valueType: 'dateTime',
      sorter: true,
      defaultSortOrder: 'descend',
    },
    {
      key: 'option',
      valueType: 'option',
      filters: false,
      filtered: false,
      //width: 120,
      render: (_, record, index, action) => {
        return (<ButtonGroup>
          <Button hint="Редактировать" type="primary" size="small" icon={<EditOutlined/>} onClick={() => { setUserEdit(record); }}/>
          {record.is_active ?
            <Button
              hint="Заблокировать"
              type="primary"
              size="small"
              danger={true}
              icon={<UsergroupDeleteOutlined/>}
              onClick={async e => {
                await Client.users.status(record.id, 0);
                tableRef.current.reload();
              }}/>
            : <Popconfirm
              title={'Разблокировать пользователя?'}
              onConfirm={async () => {
                await Client.users.status(record.id, 1);
                tableRef.current.reload();
              }}
            ><Button hint="Разблокировать" type="primary" size="small" icon={<UsergroupAddOutlined/>}/></Popconfirm>
          }
        </ButtonGroup>);
      },
    },
  ];

  const toolbarRender = () => {
    return [
      <Button
        onClick={e => setCreateUserFormVisible(true)}
        key="button"
        hint={'Новый пользователь'}
        icon={<PlusOutlined/>}
        type="primary"
      />,
    ];
  };

  const saveUser = async (data: any) => {
    if(data?.id) {
      await userUpdate(data.id,data)
    }else {
      await userCreate(data)
    }
  };

  return <>
    <TableFullHeight
      options={{ fullScreen: true }}
      actionRef={tableRef}
      headerTitle={'Пользователи'}
      columns={columns}
      rowKey={'id'}
      search={{ filterType: 'light' }}
      request={Client.users.findAll}
      toolBarRender={toolbarRender}
      onRow={(record: User, rowIndex: number) => {
        return {
          onDoubleClick: () => {
            setUserEdit(record);
          },
        };
      }}
      columnsState={{
        value: columnsStateMap,
        onChange: setColumnsStateMap,
      }}

    />


    {/* form create user*/}
    <SideForm
      onFinish={saveUser}
      formRef={createUserFormRef}
      title={'Новый пользователь'}
      visible={createUserFormVisible}
      onClose={() => setCreateUserFormVisible(false)}

    >
      <UserBaseForm data={userEdit}/>
    </SideForm>

    {/* form edit user */}

    <SideForm
      title={`Редактирование пользователя ${userEdit?.id}`}
      onFinish={saveUser}
      formRef={updateUserFormRef}
      visible={userEdit != null}
      onClose={() => setUserEdit(undefined)}

    >
      <UserBaseForm data={userEdit}/>

    </SideForm>
  </>;
};
