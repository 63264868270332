import { DrawerForm, DrawerFormProps, ProFormInstance } from '@ant-design/pro-form';

import React, { MutableRefObject, useRef } from 'react';
import { FormInstance } from 'antd/lib';
import { ProFormInstanceType } from '@ant-design/pro-components';

export declare type SideFormProps = DrawerFormProps & {
  onClose?: () => void
  loading?: boolean
}

export const SideForm = (props: SideFormProps) => {
  const formRef: React.MutableRefObject<(FormInstance<any> & ProFormInstanceType<any>) | undefined> = useRef();
  return (
    <DrawerForm {...props}
                submitter={{
                  searchConfig: {
                    submitText: 'ОК',
                    resetText: 'Отмена',
                  },

                }}
                formRef={props.formRef || formRef}
                drawerProps={{
                  afterVisibleChange: (status) => {
                    if (props.formRef != undefined) {
                      props.formRef.current.resetFields();
                    } else {
                      formRef.current?.resetFields();
                    }
                  },
                  forceRender: false,
                  destroyOnClose: true,
                  onClose: props.onClose,
                }}
    >
      {props.children}
    </DrawerForm>);
};
