import { Button as AntButton, ButtonProps as AntButtonProps, Tooltip } from 'antd';
import * as React from 'react';

export interface ButtonProps extends AntButtonProps {
  hint?: string;
}

/* просто кастомная кнопка с подсказкой */
export const Button = (props: ButtonProps) => {
  return (
    <Tooltip title={props.hint}>
      <AntButton {...props}>{props.children}</AntButton>
    </Tooltip>
  );
};
