import { message } from 'antd';
import i18n from 'i18next';
import  moment from 'moment'
import { Role } from '../services/Api';

export const showResponseError = (error: any) => {
  const _message = error.response.data.message;
  if (typeof _message == 'object') {
    Object.keys(_message).map(key => {
      message.error(i18n.t(`errors.${_message[key]}`));
    });
  } else {
    message.error(i18n.t(`errors.${_message}`));
  }
};

export const showError = (error: string) => {
  message.error(i18n.t(`errors.${error}`));
};

export const idempotenceKey = () => {};

export const getRoles = () => {
  const list: any = {};
  Object.entries(Role).map(item => {
      if (item[0] != Role.GUEST) {
        list[item[0]] = item[1];
      }
    },
  );
  return list;
};

export const EnumToList = (obj: Object) => {
  const list: any = {};
  Object.entries(obj).map(item => {
    if (!isNaN(Number(item[0]))) {
      list[item[0]] = item[1];
    } else {
      list[item[1]] = item[0];
    }
  });
  return list;
};

export const arrayOfObjectToEnum = (arr: any[], key: string | number, value: string | number) => {
  return arr.reduce((acc, elem) => {
    return {
      ...acc,
      [elem[key]]: elem[value],
    };
  }, {});
};

export const arrayToEnum = (arr: any[]) => {
  return arr.reduce((res, key) => {
    res[key] = key;
    return res;
  }, Object.create(null));
};


export const formatDate=(date:string|Date)=>{
  if(!date) return '';
  return moment(date).format('YYYY-MM-DD HH:mm:ss')
}
