import { Api, HttpClient } from './Api';
import { SERVER_URL } from '../config';
import { AppStore} from '../store';

class MedServiceApi extends Api<any> {
  private idempotenceKey = '';

  public setIdempotenceKey(key: string) {
    this.idempotenceKey = key;
    return this;
  }

  public hasIdempotenceKey(): boolean {
    return this.idempotenceKey != '';
  }

  public deleteIdempotenceKey() {
    this.idempotenceKey = '';
  }

  public getIdempotenceKey() {
    return this.idempotenceKey;
  }

}

const Client = new MedServiceApi(new HttpClient({
  baseURL: SERVER_URL,
}));

Client.http.instance.interceptors.request.use(async (config): Promise<any> => {
  let token;

  token = sessionStorage.getItem('token');

  config.headers.Authorization = `Bearer ${token}`;
  // config.headers = {
  //   ...config.headers,
  //   Authorization: `Bearer ${token}`,
  // };

  if (Client.hasIdempotenceKey()) {
    config.headers['X-Idempotence-Key'] = Client.getIdempotenceKey();
  }

  return config;
});

Client.http.instance.interceptors.response.use((resp): any => {
  Client.setIdempotenceKey('');
  return resp;
}, async (error) => {
  const originalRequest = error.config;

  if (error.response.status == 400 && typeof error.response.data.message != 'undefined') {
    AppStore.setErrors(error.response.data.message);
  }

  // if (error.response.status == 401 && originalRequest._isSend != true && error.config) {
  //   UserStore.setUser(undefined)
  // }

  throw error;

});

export { Client };
