import {MenuOutlined} from "@ant-design/icons";
import ProTable, {DragSortTable, ProTableProps} from "@ant-design/pro-table";
import React, {MutableRefObject, useContext, useRef} from "react";
import ProProvider from "@ant-design/pro-provider";
import {ProFormDateTimePicker, ProFormDateTimeRangePicker} from "@ant-design/pro-form";
import dayjs from "dayjs";
import {SelectUser} from "../form-fields/select-user";
import useFullHeightTable from "../../hooks/use-full-height-table";
import { UserButton } from '../button/user-button';

export type TableFullHeightProps = {
    dragSort: boolean;
    onSortEnd: (data: any[], tableName?: string) => void;
    dragSortKey?: string;
    autoHeight?: boolean;
    tableName?: string;
}

export const TableFullHeight = (props: any & ProTableProps<any, any, any> & TableFullHeightProps) => {


    //const [tableHeight, calculate] = useTableHeight();
    const values = useContext(ProProvider);
    const containerRef = useRef(null);
    const tableHeight = useFullHeightTable();

    const request = props.request ? async (params: any, sorter:any, filter:any) => {

        const {data} = await props.request({
            sortColumns: new URLSearchParams(sorter).toString(),
            ...params
        });
        return Promise.resolve({
            data: data.result,
            total: data?.countAll
        });
    } : false;

    const dragHandleRender = (rowData: any, idx: any) => (
        <MenuOutlined style={{cursor: "grab"}} />
    );

    return (
        <ProProvider.Provider
            value={{
                ...values,
                valueTypeMap: {
                    rangeFilterDate: {
                        render: (text) => <>{dayjs(text).format("YYYY-MM-DD HH:mm:ss")}</>,
                        renderFormItem: (text, props) => {
                            if (props.light == true) {
                                return <ProFormDateTimeRangePicker {...props?.fieldProps} />;
                            }
                            return <ProFormDateTimePicker {...props?.fieldProps} />;
                        }

                    },
                    user: {
                        render: (text, field: any) => { return <UserButton user={field.record?.user} />;},
                        renderFormItem: (user, props) => {
                            return <SelectUser {...props?.fieldProps} />;
                        }
                    }
                }
            }}
        >
            {props.dragSort ?
                <DragSortTable {...props}
                    //onDataSourceChange={props.autoHeight ? calculate : () => {}}
                               actionRef={props.actionRef}
                               scroll={{x: true, y: tableHeight}}
                               request={request}
                               dragSortKey={props?.dragSortKey || "order"}
                               onDragSortEnd={(data) => {props.onSortEnd(data, props.tableName);}}
                               dragSortHandlerRender={dragHandleRender}
                /> :
                <ProTable {...props}

                    //onDataSourceChange={props.autoHeight ? calculate : () => {}}
                          actionRef={props.actionRef}
                          scroll={{x: true, y: tableHeight}}
                          request={request}
                />}
        </ProProvider.Provider>
    );
};
