
import React, { useState } from 'react';

import { Avatar, Col, Drawer, Row } from 'antd';

import Paragraph from 'antd/lib/typography/Paragraph';
import { User } from '../../services/Api';
import { Button } from './button';


type UserButtonProps = {
  user: User
}

export const UserButton = (props: UserButtonProps) => {
  const [showUser, setShowUser] = useState(false);
  if (!props.user) return null;

  return <>
    <Button onClick={e => setShowUser(true)} size={'small'} hint={'профиль'}>{`${props.user.first_name} ${props.user.last_name}`}</Button>
    <Drawer
      destroyOnClose={true}
      visible={showUser}
      onClose={e => setShowUser(false)}
      maskClosable={true}
      width={480}
      title={`${props.user.first_name} ${props.user.last_name}`}
    >
      <Row>
        <Col span={20}><div style={{marginLeft:16}}>
          <Paragraph copyable={true}  title={'id'}>{props.user.id}</Paragraph>
          <Paragraph copyable={true}  title={'Телефон'}>{props.user.phone}</Paragraph>
        </div>
        </Col>
      </Row>

      <Row>
        <Col span={24}>
          <p><strong>Роль: </strong> {props.user.role}</p>



        </Col>
      </Row>

    </Drawer>
  </>;
};
