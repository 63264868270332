import DashboardPage from './pages/DashboardPage';
import { SitesPage } from './pages/SitesPage';
import { UsersPage } from './pages/UsersPage';

export const routes=[
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: DashboardPage,
  },
  {
    path: '/sites',
    name: 'Sites',
    component: SitesPage,
  },
  {
    path: '/users',
    name: 'Users',
    component: UsersPage,
  }
];
