// src/App.tsx
import React from 'react';
import './localization'
import ruRu from 'antd/lib/locale/ru_RU';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { AuthProvider, useAuth } from './components/auth-provider';
import LoginPage from './pages/LoginPage';
import DashboardPage from './pages/DashboardPage';
import BasicLayout from './layouts/BasicLayout';
import { SitesPage } from './pages/SitesPage';
import { routes } from './routes';
import { observer } from 'mobx-react-lite';
import { ConfigProvider } from 'antd';


const ProtectedRoute: React.FC<{ children: JSX.Element }> = ({ children }) => {
  const { token } = useAuth(); //
  return token ? children : <Navigate to="/login" />;
};

const App: React.FC = observer(() => {
  return (
    <ConfigProvider locale={ruRu}>
    <Router>
      <AuthProvider>
        <Routes>
          <Route path="/login" element={<LoginPage />} />
          <Route
            path="/"
            element={
              <ProtectedRoute>
                <BasicLayout />
              </ProtectedRoute>
            }
          >
            {routes.map(route=><Route key={route.path} path={route.path} element={<route.component/>}/>)}
          </Route>
          <Route path="*" element={<Navigate to="/login" />} />
        </Routes>
      </AuthProvider>
    </Router>
    </ConfigProvider>
  );
});

export default App;
