// src/layouts/BasicLayout.tsx
import React from 'react';
import { PageContainer, ProLayout } from '@ant-design/pro-components';
import { Outlet, useNavigate } from 'react-router-dom';
import { useAuth } from '../components/auth-provider';
import { Button } from 'antd';
import { routes } from '../routes';

interface MenuItem {
  path: string;
  name: string;
}

const BasicLayout: React.FC = () => {
  const { logout } = useAuth();
  const navigate = useNavigate();

  const menuItems: MenuItem[] = [
    ...routes,
  ];

  return (
    <ProLayout
      title="Admin Panel"
      style={{ height: '100%' }}
      menu={{
        request: async () => menuItems,

      }}
      pageTitleRender={false}
      onMenuHeaderClick={() => navigate('/dashboard')}
      menuItemRender={(item, dom) => (
        <a onClick={() => navigate(item.path || '')}>{dom}</a>
      )}
      rightContentRender={() => (
        <Button onClick={logout} type="primary">
          Logout
        </Button>
      )}
    >
      <PageContainer style={{ margin:0,padding:0 }}>
        <Outlet/>
      </PageContainer>
    </ProLayout>
  );
};

export default BasicLayout;
