import { MutableRefObject, useRef, useState } from 'react';
import { ColumnsState, ProColumns } from '@ant-design/pro-components';
import { Role, SiteStatus, User } from '../services/Api';
import { CheckCircleTwoTone, CloseCircleTwoTone, EditOutlined, PlusOutlined, UsergroupAddOutlined, UsergroupDeleteOutlined } from '@ant-design/icons';
import ButtonGroup from 'antd/es/button/button-group';
import { Button } from '../components/button/button';
import { Client } from '../services/Client';
import { Popconfirm } from 'antd';
import { TableFullHeight } from '../components/table/table-full-height';

export const SitesPage=(props:any)=>{
  const tableRef: MutableRefObject<any> = useRef();
  const [edit, setEdit] = useState<User>();
  const [columnsStateMap, setColumnsStateMap] = useState<Record<string, ColumnsState>>({});
  const [createSiteFormVisible, setCreateSiteFormVisible] = useState(false);

  const columns: ProColumns[] = [
    {
      title: 'id',
      width: 80,
      dataIndex: 'id',
      copyable: true,
      sorter: true,
      defaultSortOrder: 'descend',
    },
    {
      title: 'Дата',
      dataIndex: 'createdAt',
      //width: 50,
      valueType: 'dateTime',
      sorter: true,

    },
    {
      title: 'Описание',
      width: 150,
      dataIndex: 'title',
    },
    {
      title: 'Субдомен',
      //width: 80,
      dataIndex: 'slug',
      copyable: true,
    },
    {
      title: 'Домены',
      width: 150,
      dataIndex: 'domains',
    },
    {
      title: 'Пользователь',
      //width: 100,
      dataIndex: 'user',
    },
    {
      title: 'Статус',
      dataIndex: 'status',
      //width: 50,
      valueType: 'checkbox',
      valueEnum: SiteStatus,
    },



    {
      key: 'option',
      valueType: 'option',
      filters: false,
      filtered: false,
      //width: 120,
      render: (_, record, index, action) => {
        return (<ButtonGroup>
          <Button hint="Редактировать" type="primary" size="small" icon={<EditOutlined/>} onClick={() => { setEdit(record); }}/>
          {record.is_active ?
            <Button
              hint="Заблокировать"
              type="primary"
              size="small"
              danger={true}
              icon={<UsergroupDeleteOutlined/>}
              onClick={async e => {
                //await Client.users.status(record.id, 0);
                tableRef.current.reload();
              }}/>
            : <Popconfirm
              title={'Разблокировать?'}
              onConfirm={async () => {
                //await Client.users.status(record.id, 1);
                tableRef.current.reload();
              }}
            ><Button hint="Разблокировать" type="primary" size="small" icon={<UsergroupAddOutlined/>}/></Popconfirm>
          }
        </ButtonGroup>);
      },
    },
  ];


  const toolbarRender = () => {
    return [
      <Button
        onClick={e => setCreateSiteFormVisible(true)}
        key="button"
        hint={'Новый сайт'}
        icon={<PlusOutlined/>}
        type="primary"
      />,
    ];
  };


  return <>
    <TableFullHeight
      options={{ fullScreen: true }}
      actionRef={tableRef}
      headerTitle={'Сайты'}
      columns={columns}
      rowKey={'id'}
      search={{ filterType: 'light' }}
      request={Client.site.findAll}
      toolBarRender={toolbarRender}
      onRow={(record: User, rowIndex: number) => {
        return {
          onDoubleClick: () => {
            setEdit(record);
          },
        };
      }}
      columnsState={{
        value: columnsStateMap,
        onChange: setColumnsStateMap,
      }}

    />
  </>
}
