import { EnumToList, getRoles } from '../../common/helper';
import React from 'react';
import ProForm, { ProFormSelect, ProFormText } from '@ant-design/pro-form';
import { User } from '../../services/Api';
import { ProFormTextArea } from '@ant-design/pro-components';


interface UserBaseFormProps {
  data?: User;
}

export const UserBaseForm = (props: UserBaseFormProps) => {

  return (
    <>
      {props.data?.id && <ProFormText name={'id'} hidden initialValue={props.data.id}/>}
      <ProForm.Group>
        <ProFormText name={'phone'} label={'Телефон'} initialValue={props.data?.phone}/>
        <ProFormText name={'email'} label={'E-Mail'} initialValue={props.data?.email}/>
      </ProForm.Group>
      <ProForm.Group>
        <ProFormText name={'first_name'} label={'Имя'} initialValue={props.data?.first_name}/>
        <ProFormText name={'last_name'} label={'Фамилия'} initialValue={props.data?.last_name}/>
      </ProForm.Group>
      <ProForm.Group>
        <ProFormSelect name={'role'} valueEnum={getRoles()} label={'Роль'} initialValue={props.data?.role}/>

        <ProFormText name={'password'} label={'Пароль'}/>
      </ProForm.Group>

      <ProFormTextArea label={'Описание'} name={'description'} initialValue={props.data?.description}/>




    </>
  );
};
