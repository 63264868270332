import { useEffect, useLayoutEffect, useState } from 'react';

const useFullHeightTable = () => {
  const [tableHeight, setTableHeight] = useState(0);


  const calculateHeight = () => {

    const container = document.querySelector('.ant-pro-layout-content');
    if (!container) return;
    // @ts-ignore
    container.style.height = 'calc(100vh - 48px)';

    const containerHeight = container?.clientHeight || 0;
    const toolbar = container?.querySelector('.ant-pro-table-list-toolbar');
    const header = container?.querySelector('.ant-table-header');
    const pagination = container?.querySelector('.ant-pagination');

    const toolbarHeight = toolbar ? toolbar.clientHeight : 0;
    const headerHeight = header ? header.clientHeight : 0;
    const paginationHeight = pagination ? 56 : 0;

    const availableHeight = containerHeight - toolbarHeight - headerHeight - paginationHeight;

    setTableHeight(availableHeight > 0 ? availableHeight : 0);

  };

  useLayoutEffect(() => {
    const observer = new MutationObserver(() => {
      calculateHeight(); // Расчёт при изменениях в DOM
    });

    const container = document.querySelector('.ant-layout-content');

    if (container) {
      observer.observe(container, { childList: true, subtree: true });
    }

    calculateHeight(); // Первоначальный расчёт

    window.addEventListener('resize', calculateHeight); // Перерасчёт при изменении размера окна

    return () => {
      window.removeEventListener('resize', calculateHeight);
      if (observer) {
        observer.disconnect(); // Отключение наблюдателя
      }
    };
  }, []);

  return tableHeight;
};

export default useFullHeightTable;
