import { makeAutoObservable } from 'mobx';


class AppStore {
  loading = false;
  errors: any;


  constructor() {
    makeAutoObservable(this);
  }



  setLoading(status: boolean) {
    this.loading = status;
  }

  setErrors(errors: any) {
    this.errors = errors;
  }

  resetErrors() {
    this.errors = undefined;
  }

}

export default new AppStore();
